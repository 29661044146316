import { ReactNode, useState } from "react"
import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { TimeoutError } from "utils/promise/utils"
import { F, MSError2 } from "msutils"
import { MSForm } from "utils/form"
import { Colorset } from "compass-local/utils/colorset"
import { ClosableLayoutDONOTUSE } from "utils/ClosableLayout"
import { t } from "content"
import {
  collectErrors,
  get400ReportMessage,
  getUnmatchedErrors,
  hasMatchedFieldError,
  isAxios4XX,
  parse4XX,
} from "./utils"

export class ValidationError extends MSError2 {
  __type = "validation-error"

  constructor(message: string) {
    super(message, { reportMessage: message, name: "Validation Error" })
  }
}

export type Mutator<T> = UseMutationResult<T, Error, void, unknown>
export function Mutation<T>(
  name: ReactNode,
  props: {
    mutator: Mutator<T>
    disabled?: boolean
    disabledMessage?: string
    colors?: Colorset
    onSuccess?: (newValue: T) => void
  },
) {
  return {
    name,
    colors: props.colors,
    onClick: async () => {
      const res = await props.mutator.mutateAsync()
      props.onSuccess?.(res)
      return res
    },
    isLoading: props.mutator.isLoading,
    disabled: props.disabled,
    disabledMessage: props.disabledMessage,
  }
}

export function useFormState<T extends F.Field<any, any, any> & { _field_type: "group" }>(
  schema: T,
  props?: F.PartialConcreteConfig<T> & {
    initValue?: F.InitValue<T>
  },
) {
  const state = F.useInitSchema(schema, {
    initValue: props?.initValue,
    config: props,
  })
  const [ackableErrors, setAckableErrors] = useState<any>({})

  const useUnvalidatedAction = <R>(
    action: () => Promise<R>,
    handlers?: { onSuccess?: (res: R) => void },
  ): Mutator<R> => {
    const { setInactiveWithoutWarning } = MSForm.useFormContext()
    const closableLayoutCtx = ClosableLayoutDONOTUSE.useOptionalContext()

    return useMutation({
      mutationFn: async () => {
        const res = await action()
        handlers?.onSuccess?.(res)
        setInactiveWithoutWarning()
        closableLayoutCtx?.setInactiveWithoutWarning()
        return res
      },
      onError: (e: Error) => {
        if (e instanceof TimeoutError) {
          MSError2.report(e)
        } else if (isAxios4XX(e)) {
          const message = get400ReportMessage(e)
          const reportE = new MSError2(`Error ${e.response?.status}: ${message}`)
          MSError2.report(reportE)
        } else {
          MSError2.report(e)
        }
      },
    })
  }

  const useValidatedAction = <R>(
    action: (value: NonNullable<ReturnType<T["validate"]>>) => Promise<R>,
    settings?: {
      onSuccess?: (newValue: R) => void
      disableSetInactiveOnSuccess?: boolean
      disableErrorPropagation?: boolean
      disableLog4XX?: boolean
    },
  ): Mutator<R> => {
    const formCtx = MSForm.useOptionalFormContext()
    const closableLayoutCtx = ClosableLayoutDONOTUSE.useOptionalContext()
    const trigger = async () => {
      if (state._controller.validation.isValid) {
        try {
          const res = await action(state._controller.validation.validValue)
          settings?.onSuccess?.(res)
          if (!settings?.disableSetInactiveOnSuccess) {
            if (closableLayoutCtx) {
              closableLayoutCtx.setInactiveWithoutWarning()
            } else {
              formCtx?.setInactiveWithoutWarning()
            }
          }
          return res
        } catch (e: any) {
          if (!settings?.disableErrorPropagation) {
            state._controller.tap()
          }
          if (settings?.disableErrorPropagation) {
            const handledError = new MSError2(t("Invalid input"))
            handledError._handled = true
            throw handledError
          } else if (e instanceof ValidationError) {
            throw e
          } else if (e instanceof TimeoutError) {
            const handledError = new MSError2(t("Request timed out. Please try again."))
            handledError._handled = true
            throw handledError
          } else if (isAxios4XX(e)) {
            const parsedErrors = parse4XX(schema, e)
            setAckableErrors(parsedErrors)

            const message = get400ReportMessage(e)
            if (!settings?.disableLog4XX) {
              MSError2.report(
                `Error ${e.response?.status ?? "4XX"} at ${
                  e.response?.config.url ?? "unknown path"
                }: ${message}`,
              )
            }
            if (hasMatchedFieldError(schema, e)) {
              e.message = "Invalid input"
            } else {
              e.message = getUnmatchedErrors(schema, e)?.at(0) ?? "Invalid input"
            }
            const errorToThrow = new MSError2(e.message)
            errorToThrow._handled = true
            throw errorToThrow
          } else {
            MSError2.report(e)
          }
          const handledError = new MSError2(t("Invalid input"))
          handledError._handled = true
          throw handledError
        }
      } else {
        if (!settings?.disableErrorPropagation) {
          state._controller.tap()
        }
        MSError2.report(collectErrors(schema, state))
        throw new MSError2(state._controller.validation.error ?? "Unknown input error")
      }
    }
    return useMutation({
      mutationFn: trigger,
      // Note: this error already gets sent up to the browser for window-level error handling (which causes double-reporting for anything reported here)
      // Update: I don't think that is actually true - it seems like only the things that get explicit reports are getting reported at the top level
      // eslint-disable-next-line
      onError: (_: Error) => {},
    })
  }

  const stateWithErrors: F.Input<T> = F.attachErrors(schema, state, ackableErrors)
  return { state: stateWithErrors, useValidatedAction, useUnvalidatedAction }
}
